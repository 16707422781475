// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bee-inspirational-js": () => import("./../../../src/pages/bee-inspirational.js" /* webpackChunkName: "component---src-pages-bee-inspirational-js" */),
  "component---src-pages-bee-inspired-js": () => import("./../../../src/pages/bee-inspired.js" /* webpackChunkName: "component---src-pages-bee-inspired-js" */),
  "component---src-pages-business-page-js": () => import("./../../../src/pages/business-page.js" /* webpackChunkName: "component---src-pages-business-page-js" */),
  "component---src-pages-career-portal-embed-js": () => import("./../../../src/pages/career-portal-embed.js" /* webpackChunkName: "component---src-pages-career-portal-embed-js" */),
  "component---src-pages-career-portal-page-js": () => import("./../../../src/pages/career-portal-page.js" /* webpackChunkName: "component---src-pages-career-portal-page-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-us-page-js": () => import("./../../../src/pages/contact-us-page.js" /* webpackChunkName: "component---src-pages-contact-us-page-js" */),
  "component---src-pages-email-contact-us-js": () => import("./../../../src/pages/email-contact-us.js" /* webpackChunkName: "component---src-pages-email-contact-us-js" */),
  "component---src-pages-email-get-inspired-js": () => import("./../../../src/pages/email-get-inspired.js" /* webpackChunkName: "component---src-pages-email-get-inspired-js" */),
  "component---src-pages-giveback-js": () => import("./../../../src/pages/giveback.js" /* webpackChunkName: "component---src-pages-giveback-js" */),
  "component---src-pages-index-bak-js": () => import("./../../../src/pages/index_bak.js" /* webpackChunkName: "component---src-pages-index-bak-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-page-js": () => import("./../../../src/pages/privacy-policy-page.js" /* webpackChunkName: "component---src-pages-privacy-policy-page-js" */),
  "component---src-pages-school-page-js": () => import("./../../../src/pages/school-page.js" /* webpackChunkName: "component---src-pages-school-page-js" */),
  "component---src-pages-student-page-js": () => import("./../../../src/pages/student-page.js" /* webpackChunkName: "component---src-pages-student-page-js" */),
  "component---src-pages-terms-and-conditions-page-js": () => import("./../../../src/pages/terms-and-conditions-page.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-page-js" */),
  "component---src-pages-test-page-js": () => import("./../../../src/pages/test-page.js" /* webpackChunkName: "component---src-pages-test-page-js" */)
}

